import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const ImportProducts = () => {
    const [file, setFile] = useState(null);
const[batchId,setBatchId]=useState('');
const[progress,setProgess]=useState(1);
const [error,setError]=useState('');
const[isLoading,setIsLoading]=useState(false);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };


   

    const handleExportProducts=async(e)=>{
setIsLoading(true);
e.preventDefault();
try {
    axios({
        url: 'api/products/export',
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.xlsx'); // or any other filename you prefer
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
        setError('');
    
      });
    
   
    // Handle success response
} catch (error) {
  
    setIsLoading(false);
    // Handle error
}

    }
    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('api/import/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Response:', response.data);
            setBatchId(response.data.batchId);
            setIsLoading(false);
            setError(response.data.message);
            // Handle success response
        } catch (error) {
            console.error('Error uploading file:', error);
            setError(error.message);
            setIsLoading(false);
            // Handle error
        }
    };
    const updateProgress = useCallback(() => {
        axios.get(`api/batch/${batchId}`).then(response => {
          setProgess(response.data.progress);
          console.log(response.data);
        });
      }, [batchId]); // useCallback with batchId as dependency
      
      useEffect(() => {
        const fetchData = async () => {
          if (batchId || progress > 1) {
             updateProgress();
          }
        };
      
        fetchData();
      }, [batchId, progress, updateProgress]); // Include updateProgress in useEffect dependencies
      



    return (

        <div  style={{textAlign:'center',marginTop:'10%'}}>
{error && (

    <h5 style={{color:'green'}}>{error}</h5>
)}

{progress===100 &&(
    <h3>Task completed</h3>)}
 <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileChange} />
            <button type="submit"  className='btn btn-sm btn-primary'>{isLoading?'Please Wait...':'Upload File'}</button>
            <Link onClick={handleExportProducts}  className='btn btn-sm btn-danger'>{isLoading?'Please Wait...':'Export Products'}</Link>
         
        </form>
{batchId && (
   <div style={{backgroundColor:'lightgreen',color:'black',padding:'10px'}}> 
<div className="progress">
  <div className="progress-bar" role="progressbar" style={{width:`${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
</div>

</div>
      )}  

     
       
      
        



        </div>
    );
};

export default ImportProducts;
